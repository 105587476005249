import '../stylesheets/application_2021.sass';

import CheckIcon from '../images/check.svg';
import CouponIcon from '../images/coupon.svg';
import DiscountIcon from '../images/discount-code.svg';
import PdfIcon      from '../images/pdf_icon.svg';
import SuppliesIcon from '../images/supplies_icon.svg';
import VideoIcon    from '../images/video_icon.svg';

import '../images/application_2021/arrow-down-blue.svg';
import '../images/application_2021/arrow-up-blue.svg';
import '../images/application_2021/certificates/icon-bio.svg';
import '../images/application_2021/certificates/icon-certified.svg';
import '../images/application_2021/certificates/icon-eco.svg';
import '../images/application_2021/certificates/icon-gots-circle.svg';
import '../images/application_2021/certificates/icon-gots.svg';
import '../images/application_2021/certificates/icon-madeineu.svg';
import '../images/application_2021/check-grey.svg';
import '../images/application_2021/check-white.svg';
import '../images/application_2021/close-grey.svg';
import '../images/application_2021/close-white.svg';
import '../images/application_2021/download-blue.svg';
import '../images/application_2021/icon-account.svg';
import '../images/application_2021/icon-arrow-left.svg';
import '../images/application_2021/icon-arrow-right.svg';
import '../images/application_2021/icon-cart-white.svg';
import '../images/application_2021/icon-cart.svg';
import '../images/application_2021/icon-clock.svg';
import '../images/application_2021/icon-close.svg';
import '../images/application_2021/icon-comment.svg';
import '../images/application_2021/icon-delete.svg';
import '../images/application_2021/icon-edit.svg';
import '../images/application_2021/icon-error-red.svg';
import '../images/application_2021/icon-error.svg';
import '../images/application_2021/icon-info-orange.svg';
import '../images/application_2021/icon-info.svg';
import '../images/application_2021/icon-like.svg';
import '../images/application_2021/icon-magazine';
import '../images/application_2021/icon-pdf';
import '../images/application_2021/icon-plus.svg';
import '../images/application_2021/icon-retoure.svg';
import '../images/application_2021/icon-sample.svg';
import '../images/application_2021/icon-search.svg';
import '../images/application_2021/icon-share.svg';
import '../images/application_2021/icon-sort.svg';
import '../images/application_2021/icon-success-green.svg';
import '../images/application_2021/icon-success.svg';
import '../images/application_2021/icon-supplies';
import '../images/application_2021/icon-video';
import '../images/application_2021/icon-wishlist-filled.svg';
import '../images/application_2021/icon-wishlist.svg';
import '../images/application_2021/makerist-main-logo-small.svg';
import '../images/application_2021/makeristar_color_icon.svg';
import '../images/application_2021/move-blue.svg';
import '../images/application_2021/plus-white.svg';
import '../images/application_2021/presentation-block.svg';
import '../images/application_2021/presentation-grid.svg';
import '../images/application_2021/search-grey.svg';
import '../images/application_2021/social_media/facebook-grey.svg';
import '../images/application_2021/social_media/instagram-grey.svg';
import '../images/application_2021/social_media/pinterest-grey.svg';
import '../images/application_2021/social_media/youtube-grey.svg';
import '../images/application_2021/user-grey.svg';

if (!window.MK) window.MK = {};

require('@rails/ujs').start();
require('turbolinks').start();

// base
require('../application_2021/base/browser_locale');

import { i18n } from '../i18n/i18n-loader';
window.I18n = i18n;

require('../application_2021/base/i18n_init');
require('../application_2021/base/top_nav_link_preview');
require('../application_2021/base/flash');

// external
require('../application_2021/global_search/autocomplete_global_search');
if (window.location.pathname.includes('search')){
  require('../application_2021/global_search/autocomplete_page_search');
}
require('../application_2021/global_search/ios_fix');
require('../application_2021/show_more_button');
require('jquery.raty');
require('../application_2021/page_navigation');
require('../application_2021/tree_folding_handler.js');
require('../application_2021/my_makerist_gallery_swiper');
require('../application_2021/works_page_gallery_swiper');
require('../application_2021/home_teasers_swiper');
require('../application_2021/banners_swiper');
require('../application_2021/works_swiper');
require('../application_2021/products_swiper');
require('../application_2021/navigation_filter');
require('../application_2021/product_page_gallery_swiper');
require('../application_2021/product_quantity_input');
require('../application_2021/product_page_accordion');
require('../application_2021/product_page_variations_swiper');
require('../application_2021/footer');
require('../application_2021/review_modal');
require('../application_2021/pattern_pdf_downloader');
require('../application_2021/show_all_reviews_button');
require('../application_2021/toggle_password');
require('../application_2021/footer_show_more_button');
require('../application_2021/auto_submit');
require('../application_2021/keep_scroll_position');
require('../application_2021/topic');
require('../application_2021/modal');
require('../application_2021/password_validation');
require('../application_2021/work_buttons');
require('../application_2021/copy_to_clipboard');
require('../application_2021/jump_to_products');
require('../application_2021/back_to_top_button');
require('../application_2021/order_pattern_notice');

require('../application_2021/helpers/truncate_string');

require('../application_2021/my/profile');
require('../application_2021/my/teasers_swiper');
require('../application_2021/my/course_video_swiper');
require('../application_2021/my/upload_control');
require('../application_2021/my/project_lists');
require('../application_2021/my/orders');

require('mmenu-js');
require('../application_2021/base/mmenu');

// modules
MK.BraintreeCardPayment = require('../application_2021/modules/braintree_card_payment');
MK.BraintreePaypalPayment = require('../application_2021/modules/braintree_paypal_payment');
MK.Chooser = require('../application_2021/modules/chooser');
MK.FlashMessage = require('../application_2021/modules/flash_message');
MK.OrderInvoiceAddress = require('../application_2021/modules/order_invoice_address');
MK.OrderShippingAddress = require('../application_2021/modules/order_shipping_address');
MK.SubmitParentForm = require('../application_2021/modules/submit_parent_form');
MK.UploadControl = require('../application_2021/modules/upload_control');
MK.ViewablePassword = require('../application_2021/modules/viewable_password');
require('../application_2021/modules/mobile_nav_filters');

require('../application_2021/tooltips');
require('../application_2021/dom_ready');
require('../application_2021/payments');
require('../application_2021/coupon_code_error_hint');
require('../application_2021/coupon_code_field')

// expose swiper.js for dynamic yield
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
window.DYSwiper = Swiper;
window.DYSwiperAutoplay = Autoplay;
window.DYSwiperNavigation = Navigation;
